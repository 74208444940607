@font-face {
  font-family: 'FuturaPT-Light';
  src: url('FuturaPTLight.ttf') format('truetype');
  src: url('FuturaPTLight.otf') format('opentype');
  src: url('FuturaPTLight.woff') format('woff');
}

@font-face {
  font-family: 'FuturaPT-Book';
  src: url('FuturaPTBook.ttf') format('truetype');
  src: url('FuturaPTBook.otf') format('opentype');
  src: url('FuturaPTBook.woff') format('woff');
}
